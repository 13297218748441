import { Button } from '@mui/material'
import styled from '@styled'

// Home components

export const HomeContainer = styled.div`
	overflow-y: auto;
	height: 100%;
	padding: ${({ theme }) => theme.spacing(1, 0, 2, 0)};
	background-color: #f2f2f2;
`

export const WorkspacesList = styled.div`
	padding: 50px;
	padding-top: 8px;

	width: 100%;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing(3)};
`

export const WorkspaceCardContainer = styled(Button)`
	border-color: ${({ theme }) => theme.palette.divider};
	text-transform: unset;
	border-radius: 10px;
	width: 280px;
	height: 300px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(2)};

	& > img {
		height: 80px;
		max-height: 80px;
		max-width: 170px;
		object-fit: contain;
	}
`
WorkspaceCardContainer.defaultProps = {
	variant: 'outlined',
}

export const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-template-areas:
		'name name name'
		'title preview uploader'
		'error error error';
	gap: 8px 8px;
	place-items: center;
	margin-top: 8px;

	.name {
		grid-area: name;
		width: 100%;
		margin-bottom: ${({ theme }) => theme.spacing(1)};
	}
	.title {
		grid-area: title;
	}
	.uploader {
		grid-area: uploader;
	}
	.error {
		grid-area: error;
	}
`

export const WorkspaceAvatar = styled.div`
	grid-area: preview;
	display: flex;
	display: grid;
	place-items: center;

	img {
		height: 70px;
		object-fit: cover;
	}
`

export const HelperContainer = styled.div`
	width: 25%;
	min-width: 460px;
	background-color: #fff;
	border-radius: 5px;
	display: flex;
	justify-content: center;
	column-gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	margin: 0 auto 16px;
	padding: ${({ theme }) => theme.spacing(1, 2)};
	box-shadow: ${({ theme }) => theme.shadows[2]};
	font-size: 14px;
`
