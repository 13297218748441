import { Add } from '@mui/icons-material';
import { Alert, AlertTitle, Box, Button, CircularProgress, DialogActions, DialogContent, Fade, TextField, Typography } from '@mui/material';
import { ModalWrapper } from 'components/Modals';
import { getTimezone } from 'lib/utils';
import _ from 'lodash';
import SubmitButton from 'organisms/buttons/SubmitButton';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import OrganizationsSvc from 'services/OrganizationsSvc';
import slugify from 'slugify';
import { useDebouncedCallback } from 'use-debounce';
const forbidden_chars = /[\.%<>\[\]{}\|\/\\\^\+]/;
const initialErrorState = {
  status: false,
  content: null
};
export default function CreateOrganizationModal({
  is_creating,
  createOrganization,
  create_successfully
}) {
  const intl = useIntl();
  const [step, setStep] = useState(0);
  const modalRef = useRef();
  function onCancel() {
    modalRef.current?.close();
    setStep(0);
  }

  /* ----- React LifeCycle ------ */

  return <ModalWrapper ref={modalRef} size='md' hasCloseButton={false} title={is_creating ? intl.messages['modal.header.organization_creating'] : intl.messages['modal.header.organization_creation']} trigger={<Button variant='contained' endIcon={<Add />} sx={{
    justifySelf: 'end',
    mr: 1
  }}>
					<FormattedMessage id='modal.header.organization_creation_button' />
				</Button>}>
			{step === 0 && <WarningStep setStep={setStep} onCancel={onCancel} />}
			{step === 1 && <CreationStep is_creating={is_creating} create_successfully={create_successfully} createOrganization={createOrganization} onCancel={onCancel} />}
		</ModalWrapper>;
}
CreateOrganizationModal.propTypes = {
  is_creating: PropTypes.bool,
  create_successfully: PropTypes.bool,
  createOrganization: PropTypes.func
};
function WarningStep({
  setStep,
  onCancel
}) {
  const intl = useIntl();
  return <>
			<DialogContent>
				<Alert severity='warning'>
					<AlertTitle>{intl.messages['common.warning']}</AlertTitle>
					<Typography variant='body2' gutterBottom>
						<FormattedMessage id='modal.organization.creation_description.header' />
					</Typography>
					<Box my={2} lineHeight={2}>
						<FormattedMessage id='modal.organization.creation_description.content' />
					</Box>

					<Box my={2}>
						<Typography variant='h4' color='primary'>
							{intl.messages['modal.organization.creation_description.support']}
						</Typography>
					</Box>
				</Alert>
			</DialogContent>

			<DialogActions>
				<Button onClick={onCancel}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<Button variant='outlined' color='secondary' onClick={() => setStep(1)}>
					<FormattedMessage id='button.next' />
				</Button>
			</DialogActions>
		</>;
}
WarningStep.propTypes = {
  setStep: PropTypes.func,
  onCancel: PropTypes.func
};
function CreationStep({
  is_creating,
  create_successfully,
  createOrganization,
  onCancel
}) {
  const intl = useIntl();
  const [error, setError] = useState(initialErrorState);
  const [name, setName] = useState('');
  const [slug, setSlug] = useState('');
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleError();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [name, slug]);
  async function onValidateSlug(slug) {
    setLoading(true);
    const isSlugValid = await OrganizationsSvc.validateSlug(slug);
    if (!isSlugValid) {
      setError({
        status: true,
        content: <FormattedMessage id='organization.profile.ID_exists' />
      });
    } else if (_.isEmpty(slug)) {
      setError({
        status: true,
        content: <FormattedMessage id='organization.profile.ID_empty' />
      });
    } else {
      setError(initialErrorState);
    }
    setLoading(false);
  }
  const debouncedValidate = useDebouncedCallback(onValidateSlug, 400);
  function handleNameKeyPress(evt) {
    // Forbid non URL-friendly characters
    if (forbidden_chars.test(evt.key)) {
      evt.preventDefault();
    }
  }
  function handleError() {
    const error = verifyForm();
    if (error.status) {
      setError(error);
    } else {
      // reset Error
      setError(initialErrorState);
    }
    return error.status;
  }
  function handleNameChange({
    target: {
      value
    }
  }) {
    setName(value);
    handleSlugChange({
      target: {
        value
      }
    });
  }
  function handleSlugChange({
    target: {
      value
    }
  }) {
    const slugValue = slugify(value, {
      remove: null,
      lower: true,
      remove: /[*+~%\<>§/;`=.(){}?,'"!:@#^|]/g
    });
    setSlug(slugValue);
    setLoading(true);
    debouncedValidate(slugValue);
  }
  function verifyForm() {
    if (!name) {
      return {
        status: true,
        content: <FormattedMessage id='modal.organization_creation.error.missing_organization_name' />
      };
    }
    if (!slug) {
      return {
        status: true,
        content: <FormattedMessage id='modal.organization_creation.error.missing_organization_slug' />
      };
    }
    return {
      status: false,
      content: null
    };
  }
  async function submitForm(evt) {
    evt.preventDefault();
    if (error.status) {
      return;
    }
    try {
      const timezone = getTimezone();
      await createOrganization({
        name,
        slug,
        timezone
      });
      onClose();
    } catch (error) {
      console.log(error);
    }
  }
  function onClose() {
    setName('');
    setSlug('');
    onCancel();
  }
  return <>
			<DialogContent>
				{/* <Typography variant='h2' color='primary'>
     Enter organization name and unique slug
     </Typography> */}
				<Box my={1} width={600}>
					<TextField size='small' fullWidth={true} variant='outlined' value={name} onChange={handleNameChange} onKeyPress={handleNameKeyPress} label={intl.messages['modal.organization_creation.name']} />
				</Box>

				<Box my={1} width={600}>
					<TextField size='small' fullWidth={true} variant='outlined' value={slug} onChange={handleSlugChange} onKeyPress={handleNameKeyPress} error={error.status} label={intl.messages['modal.organization_creation.slug']} />
				</Box>

				{error.status && <Fade in={error.status}>
						<Alert severity='error'>{error.content}</Alert>
					</Fade>}
			</DialogContent>

			<DialogActions>
				<Button onClick={onClose}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<SubmitButton loading={is_creating} disabled={error.status || loading || create_successfully} onClick={submitForm}>
					{loading || create_successfully ? <CircularProgress size='20px' /> : <FormattedMessage id='button.confirm' />}
				</SubmitButton>
			</DialogActions>
		</>;
}
CreationStep.propTypes = {
  is_creating: PropTypes.bool,
  create_successfully: PropTypes.bool,
  createOrganization: PropTypes.func,
  onCancel: PropTypes.func
};