import { PencilIcon, UserIcon } from '@heroicons/react/20/solid';
import { CalendarIcon, ClockIcon } from '@heroicons/react/24/outline';
import { Box, Typography } from '@mui/material';
import { SnackeetCardArea, SnackeetCardBody, SnackeetCardDateSection, SnackeetCardInfo, SnackeetCardLabel, SnackeetCardLogo, SnackeetOrganizationEditButton } from 'components/styled/SnackeetCard';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { getCustomerRole } from 'redux/customer/selectors';
import { snackeet_blue, snackeet_teal, snackeet_yellow } from 'styles/colors';
export default function OrganizationCard({
  organization,
  isCurrentOrganization,
  currentCustomer,
  ...delegatedProps
}) {
  const {
    owner,
    name,
    logo,
    email,
    username,
    created_at,
    updated_at,
    members_count,
    available_seats,
    license_key,
    members
  } = organization;
  const customerRole = useSelector(getCustomerRole);
  const roleInOrganization = getRoleInOrganization({
    customerRole,
    customer_id: currentCustomer,
    members,
    owner
  });
  return <SnackeetCardArea {...delegatedProps}>
			<SnackeetCardBody href={`/${organization.slug}`} prefetch={false} sx={{
      gridTemplateColumns: 'minmax(50px, 10%) 1fr 20%'
    }}>
				<SnackeetCardLogo>
					<img src={logo} />
				</SnackeetCardLogo>

				<SnackeetCardInfo>
					<Box display='flex' alignItems='center' typography='body2' columnGap={1}>
						<SnackeetCardLabel $color={getRoleTheme(roleInOrganization)}>
							<FormattedMessage id={`organization.role.${roleInOrganization || 'agent'}`} />
						</SnackeetCardLabel>
						<Box fontWeight='bold'>
							{available_seats === 'Infinity' ? <FormattedMessage values={{
              count: members_count
            }} id='organization.table_title.current_members' /> : <FormattedMessage values={{
              count: members_count,
              seats: available_seats
            }} id='organization.table_title.members' />}
						</Box>
					</Box>

					<Typography color='textPrimary' variant='h2' sx={{
          width: 'fit-content',
          fontWeight: 600,
          fontSize: '1.10rem',
          lineHeight: '1.25rem',
          '&:hover': {
            textDecoration: 'underline'
          }
        }}>
						{isCurrentOrganization ? `${name} (Current)` : name}
					</Typography>

					<SnackeetCardDateSection>
						<Typography variant='body2' component='div'>
							<CalendarIcon />
							<Typography variant='body2' component='label'>
								<FormattedMessage id='stories_list.created' />
							</Typography>
							<Box fontWeight='bold'>
								<FormattedDate value={new Date(created_at)} year='numeric' month='numeric' day='numeric' />
							</Box>
						</Typography>

						<Typography variant='body2' component='div'>
							<ClockIcon />
							<Typography variant='body2' component='label'>
								<FormattedMessage id='organization.table_title.last_update' />
							</Typography>
							<Box fontWeight='bold'>
								<FormattedDate value={new Date(updated_at)} year='numeric' month='numeric' day='numeric' />
							</Box>
						</Typography>

						<Typography variant='body2' component='div'>
							<UserIcon />
							<Typography variant='body2' component='label'>
								<FormattedMessage id='organization.table_title.owner_email' />
							</Typography>
							<Box fontWeight='bold'>{customerRole === 'super_admin' ? email : username}</Box>
						</Typography>
					</SnackeetCardDateSection>
				</SnackeetCardInfo>
				<Box sx={{
        display: 'grid',
        placeItems: 'center',
        p: 1
      }}>
					{license_key && (roleInOrganization === 'owner' || customerRole === 'super_admin') && <img src='/static/images/appsumo-logo.png' style={{
          width: '100%'
        }} />}
				</Box>
			</SnackeetCardBody>
			{['admin', 'owner'].includes(roleInOrganization) && <SnackeetOrganizationEditButton href={`/${organization.slug}/settings?tab=members`}>
					<PencilIcon />
				</SnackeetOrganizationEditButton>}
		</SnackeetCardArea>;
}
OrganizationCard.propTypes = {
  organization: PropTypes.object.isRequired,
  isCurrentOrganization: PropTypes.bool,
  currentCustomer: PropTypes.string
};
function getRoleInOrganization({
  customerRole,
  customer_id,
  members,
  owner
}) {
  if (customer_id === owner) {
    return 'owner';
  }
  if (customerRole === 'super_admin') {
    return 'admin';
  }
  const customerMember = _.find(members, ['_id', customer_id]);
  return customerMember?.role;
}
function getRoleTheme(role) {
  switch (role) {
    case 'owner':
      return snackeet_teal;
    case 'admin':
      return snackeet_yellow;
    default:
      return snackeet_blue;
  }
}