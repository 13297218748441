import { Box, InputLabel, MenuItem, Pagination, TextField, Typography } from '@mui/material';
import SearchBar from 'components/atoms/SearchBar';
import Loader from 'components/Loader';
import { ConfirmationModal } from 'components/Modals';
import { AppHeader } from 'components/styled';
import { HomeContainer } from 'components/styled/HomePage';
import { FilterLine } from 'components/styled/ListPages';
import { sleep } from 'lib/utils';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerId } from 'redux/customer/selectors';
import { getCurrentOrganizationId } from 'redux/organization/selectors';
import { updateOrganization } from 'redux/organization/slice';
import { updateSubscription } from 'redux/subscription/slice';
import OrganizationSvc from 'services/OrganizationsSvc';
import useAsyncEffect from 'use-async-effect';
import { useImmer } from 'use-immer';
import ActivateLicenseModal from './ActivateLicenseModal';
import CreateOrganizationModal from './CreateOrganizationModal';
import InvitationCard from './InvitationCard';
import LicenseCard from './LicenseCard';
import OrganizationCard from './OrganizationCard';
export default function OrganizationsPage() {
  const intl = useIntl();
  const router = useRouter();
  const {
    enqueueSnackbar
  } = useSnackbar();
  const customerId = useSelector(getCustomerId);
  const currentOrganization = useSelector(getCurrentOrganizationId);
  const invitationUnavailableRef = useRef();
  const activateModalRef = useRef();
  const dispatch = useDispatch();
  const [state, updateState] = useImmer({
    isLoadingInvitations: true,
    isLoadingLicenses: true,
    isLoadingOrganizations: true,
    is_creating: false,
    create_successfully: false,
    invitationsList: [],
    licensesList: [],
    licenseIdToActivate: '',
    organizations: [],
    organizationsCount: undefined,
    totalOrganizationsCount: undefined,
    searchQuery: '',
    pageCount: 1,
    perPage: 20,
    pageNumber: 1
  });
  const invite = _.find(state.invitationsList[0]?.invitations, user => user.invited_at === state.invitationsList[0]?.invited_at);
  function handleSearch(value) {
    updateState(draft => {
      draft.searchQuery = value;
    });
  }

  /* ---- Api functions ---- */

  async function createOrganization({
    name,
    slug,
    timezone
  }) {
    try {
      updateState(draft => {
        draft.is_creating = true;
      });
      const {
        organization
      } = await OrganizationSvc.create({
        name,
        slug,
        timezone
      });
      updateState(draft => {
        draft.is_creating = false;
        draft.create_successfully = true;
      });
      enqueueSnackbar('Create Organization Success', {
        variant: 'success'
      });
      await sleep();
      router.push(`/${organization.slug}`);
    } catch (err) {
      console.error('❌ createOrganization', err);
      enqueueSnackbar('Create Organization Error', {
        variant: 'error'
      });
      updateState(draft => {
        draft.is_creating = false;
      });
    }
  }
  async function activateLicense(organization_id) {
    try {
      updateState(draft => {
        draft.is_creating = true;
      });
      const {
        organization
      } = await OrganizationSvc.activateLicense({
        organization_id,
        license_id: state.licenseIdToActivate
      });
      updateState(draft => {
        draft.is_creating = false;
        draft.create_successfully = true;
      });
      dispatch(updateOrganization(organization));
      if (organization_id === currentOrganization) {
        const license = await OrganizationSvc.getSubscription(organization_id);
        dispatch(updateSubscription(license));
      }
      enqueueSnackbar('Activate License Success', {
        variant: 'success'
      });
      await sleep();
      router.push(`/${organization.slug}/settings?tab=subscription`);
    } catch (err) {
      console.error('❌ activateLicense', err);
      enqueueSnackbar('Activate License Error', {
        variant: 'error'
      });
      updateState(draft => {
        draft.is_creating = false;
      });
    }
  }
  async function rejectInvitation(_id) {
    try {
      const data = await OrganizationSvc.respondInvitation(_id, false);
      updateState(draft => {
        draft.invitationsList = draft.invitationsList.filter(invitation => invitation._id !== _id);
      });
      enqueueSnackbar('Reject Invitation Success', {
        variant: 'success'
      });
    } catch (err) {
      console.error('❌ RejectInvitation', err);
      enqueueSnackbar('Reject Invitation Error', {
        variant: 'error'
      });
    }
  }
  async function acceptInvitation(_id, forbidden = false) {
    try {
      updateState(draft => {
        draft.isLoadingInvitations = true;
      });
      const data = await OrganizationSvc.respondInvitation(_id, true);
      const organization = await OrganizationSvc.get(_id);
      enqueueSnackbar('Accept Invitation Success', {
        variant: 'success'
      });
      updateState(draft => {
        draft.isLoadingInvitations = false;
      });
      await sleep();
      router.push(`/${organization.slug}`);
    } catch (err) {
      updateState(draft => {
        draft.isLoadingInvitations = false;
      });

      // If organization reach maximum number of members
      // Then open warning modal
      if (forbidden) {
        invitationUnavailableRef.current.open();
      }
      console.error('❌ AcceptInvitation', err);
      enqueueSnackbar('Accept Invitation Error', {
        variant: 'error'
      });
    }
  }
  function onOpenActivateModal(licenseId) {
    activateModalRef.current.open();
    updateState(draft => {
      draft.licenseIdToActivate = licenseId;
    });
  }
  function handlePageChange(evt, pageIndex) {
    updateState(draft => {
      draft.pageNumber = pageIndex;
    });
  }
  function handlePerPageChange({
    target: {
      value
    }
  }) {
    updateState(draft => {
      draft.perPage = value;
    });
  }
  const {
    isLoadingInvitations,
    isLoadingLicenses,
    isLoadingOrganizations,
    is_creating,
    create_successfully,
    searchQuery,
    organizations,
    organizationsCount,
    totalOrganizationsCount,
    pageCount,
    pageNumber,
    perPage,
    invitationsList,
    licensesList
  } = state;

  // Initialize Licenses & Invitations
  useAsyncEffect(async isMounted => {
    try {
      // Get pending invitations & licenses
      const [invitationsList, licensesList] = await Promise.all([OrganizationSvc.getInvitationsList(), OrganizationSvc.getLicensesList()]);
      if (isMounted()) {
        updateState(draft => {
          draft.invitationsList = invitationsList;
          draft.licensesList = licensesList;
        });
      }
    } catch (error) {
      console.error('Load invitations & licenses', error);
    } finally {
      if (isMounted()) {
        updateState(draft => {
          draft.isLoadingInvitations = false;
          draft.isLoadingLicenses = false;
        });
      }
    }
  }, []);

  // Load organizations
  useAsyncEffect(async isMounted => {
    try {
      updateState(draft => {
        draft.isLoadingOrganizations = true;
      });
      const {
        organizationsCount,
        pageCount,
        organizations
      } = await OrganizationSvc.getList({
        pageNumber,
        perPage,
        searchQuery
      });
      if (isMounted()) {
        updateState(draft => {
          draft.organizations = organizations;
          draft.organizationsCount = organizationsCount;
          draft.pageCount = pageCount;
          if (draft.totalOrganizationsCount === undefined) {
            draft.totalOrganizationsCount = draft.organizationsCount;
          }
        });
      }
    } catch (error) {
      console.error('Load organizations Error', error);
    } finally {
      if (isMounted()) {
        updateState(draft => {
          draft.isLoadingOrganizations = false;
        });
      }
    }
  }, [pageNumber, perPage, searchQuery]);
  if (isLoadingInvitations && isLoadingLicenses && isLoadingOrganizations) {
    return <>
				<OrganizationsPageHeader />
				<Loader />
			</>;
  }
  return <>
			<OrganizationsPageHeader>
				<CreateOrganizationModal is_creating={is_creating} create_successfully={create_successfully} createOrganization={createOrganization} />
			</OrganizationsPageHeader>

			<HomeContainer id='home'>
				{!_.isEmpty(licensesList) && <>
						<FilterLine minHeight={60}>
							<Typography variant='h1' color='primary'>
								{intl.messages['organization.title.pending.licences']}
							</Typography>
						</FilterLine>

						<Box display='flex' flexDirection='column' my={2} mx='auto' width='80%' rowGap={1}>
							{licensesList.map(license => <LicenseCard key={license._id} license={license} onOpenActivateModal={onOpenActivateModal} />)}
						</Box>
					</>}

				{!_.isEmpty(invitationsList) && <>
						<FilterLine minHeight={60}>
							<Typography variant='h1' color='secondary'>
								<FormattedMessage values={{
              count: invitationsList.length
            }} id='organization.title.pending.invitations' />
							</Typography>
						</FilterLine>

						<Box display='flex' flexDirection='column' my={2} mx='auto' width='80%' rowGap={1}>
							{invitationsList.map(invitation => <InvitationCard key={invitation._id} invitation={invitation} invited_by={invite?.invited_by} rejectInvitation={rejectInvitation} acceptInvitation={acceptInvitation} />)}
						</Box>
					</>}

				<FilterLine minHeight={60}>
					<Typography variant='h1' color='textPrimary'>
						<FormattedMessage values={{
            count: organizationsCount
          }} id='common.organizations_count' />
					</Typography>

					{totalOrganizationsCount > 5 && <SearchBar fullWidth={false} value={searchQuery} onChange={handleSearch} placeholder={intl.messages['organization.search.placeholder']} sx={{
          maxWidth: 'unset',
          width: '330px'
        }} disableUnderline />}

					{(totalOrganizationsCount > 20 || pageCount > 1) && <Box display='flex' alignItems='flex-end' gap={2}>
							{totalOrganizationsCount > 20 && <Box>
									<InputLabel sx={{
              fontWeight: 500,
              textAlign: 'start'
            }}>
										<FormattedMessage id='common.per_page' />
									</InputLabel>
									<TextField select size='small' value={perPage} onChange={handlePerPageChange} sx={{
              minWidth: 80,
              backgroundColor: 'background.paper'
            }}>
										<MenuItem value={20}>20</MenuItem>
										<MenuItem value={50}>50</MenuItem>
										<MenuItem value={100}>100</MenuItem>
									</TextField>
								</Box>}

							{pageCount > 1 && <Pagination size='small' count={pageCount} variant='outlined' color='primary' onChange={handlePageChange} page={pageNumber} showFirstButton={pageCount > 5} showLastButton={pageCount > 5} />}
						</Box>}
				</FilterLine>

				{isLoadingOrganizations ? <Loader /> : <Box display='flex' flexDirection='column' my={2} mx='auto' width='80%' rowGap={3}>
						{_.map(organizations, (organization, index) => <OrganizationCard key={organization._id} organization={organization} isCurrentOrganization={organization._id === currentOrganization} currentCustomer={customerId} data-intercom-target={index === 0 ? 'First Organization' : undefined} />)}
					</Box>}
			</HomeContainer>

			<ConfirmationModal size='sm' confirmationModalRef={invitationUnavailableRef} title={intl.messages['modal.organization_accept.forbidden.header']} firstLine={<FormattedMessage id='modal.organization_accept.forbidden.title' />} secondLine={<FormattedMessage id='modal.organization_accept.forbidden.description' />} cancelText={intl.messages['button.confirm']} />

			<ActivateLicenseModal activateModalRef={activateModalRef} organizationList={_.filter(organizations, organization => organization.chargebee_id && organization.owner === customerId)} activateLicense={activateLicense} is_creating={state.is_creating} create_successfully={state.create_successfully} />
		</>;
}
function OrganizationsPageHeader({
  children
}) {
  return <AppHeader>
			<Typography color='textPrimary' variant='h3' sx={{
      ml: 2
    }}>
				<FormattedMessage id='organization.app_header.title' />
			</Typography>
			{children}
		</AppHeader>;
}
OrganizationsPageHeader.propTypes = {
  children: PropTypes.node
};