import { Box, Button, Typography } from '@mui/material';
import { SnackeetCardArea, SnackeetCardBody, SnackeetCardDateSection, SnackeetCardInfo } from 'components/styled/SnackeetCard';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
const LICENCES_TIERS = {
  snackeet_tier1: {
    fr: 'License Tiers 1',
    en: 'Licence Tiers 1'
  },
  snackeet_tier2: {
    fr: 'License Tiers 2',
    en: 'Licence Tiers 2'
  },
  snackeet_tier3: {
    fr: 'License Tiers 3',
    en: 'Licence Tiers 3'
  },
  snackeet_tier4: {
    fr: 'License Tiers 4',
    en: 'Licence Tiers 4'
  }
};
export default function LicenseCard({
  license,
  onOpenActivateModal
}) {
  const customer = useSelector(state => state.customer);
  const {
    _id,
    platform,
    planId
  } = license;
  return <>
			<SnackeetCardArea>
				<SnackeetCardBody sx={{
        pointerEvents: 'none',
        gridTemplateColumns: 'minmax(100px, 150px) 1fr!important',
        gridGap: '32px!important'
      }}>
					<Box position='relative' height={24}>
						<Image src='/static/images/appsumo-logo.png' fill style={{
            objectFit: 'scale-down'
          }} />
					</Box>

					<SnackeetCardInfo sx={{
          height: '5em!important',
          justifyContent: 'space-evenly!important'
        }}>
						<Typography color='textPrimary' variant='h2' sx={{
            width: 'fit-content',
            fontWeight: 600,
            fontSize: '1.10rem',
            lineHeight: '1.25rem'
          }}>
							{LICENCES_TIERS[planId][customer.locale]}
						</Typography>

						<SnackeetCardDateSection>
							<Typography variant='body2' component='div'>
								<FormattedMessage id='organization.licence.subtitle' />
							</Typography>
						</SnackeetCardDateSection>
					</SnackeetCardInfo>
				</SnackeetCardBody>

				<Box sx={{
        flexShrink: 0,
        flexBasis: '200px',
        minHeight: 140,
        display: 'flex',
        flexDirection: 'column'
      }}>
					<Button color='success' variant='outlined' onClick={() => onOpenActivateModal(_id)} sx={{
          flex: 1,
          borderRadius: 0
        }}>
						<FormattedMessage id='button.activate' />
					</Button>
				</Box>
			</SnackeetCardArea>
		</>;
}
LicenseCard.propTypes = {
  license: PropTypes.object.isRequired,
  onOpenActivateModal: PropTypes.func
};