import { Box } from '@mui/material'
import styled from '@styled'

export const ListPageLayout = styled.div`
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	padding: ${({ theme }) => theme.spacing(1, 0)};
`

export const EmptyContentWrapper = styled.div`
	margin: ${({ theme }) => theme.spacing(2)};
	display: flex;
	flex-direction: column;
	align-items: center;

	& > div {
		margin: ${({ theme }) => theme.spacing(1)};
	}

	.title {
		text-align: center;
		font-weight: bold;
		font-size: 20px;
		margin: 8px;
	}

	.subtitle {
		text-align: center;
		font-size: 16px;
		margin: 8px;
	}

	img {
		height: 200px;
		width: auto;
	}
`

export const ContentListWrapper = styled(Box)`
	text-align: left;
	overflow-y: auto;
	overflow-x: hidden;
`
ContentListWrapper.defaultProps = {
	display: 'grid',
	gap: 3,
	gridTemplateColumns: 'repeat(auto-fill, minmax(480px, 1fr))',
	py: 2,
	px: '4%',
	height: 'max-content',
	width: '100%',
}

export const FilterLine = styled(Box)`
	padding: ${({ theme }) => theme.spacing(1)};

	h1 {
		font-weight: 600;
		margin-bottom: ${({ theme }) => theme.spacing(1)};
	}
`
FilterLine.defaultProps = {
	width: '100%',
	maxWidth: 1300,
	display: 'flex',
	alignItems: 'flex-end',
	justifyContent: 'space-evenly',
	flexWrap: 'wrap',
	gap: 1,
}
