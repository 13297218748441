import Layout from 'containers/Layout';
import OrganizationsPage from 'containers/OrganizationsPage';
import * as React from 'react';
export class IndexPage extends React.Component {
  render() {
    return <Layout>
				<OrganizationsPage />
			</Layout>;
  }
}
export default IndexPage;