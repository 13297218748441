import { Box, Button, CircularProgress, DialogActions, DialogContent, Typography } from '@mui/material';
import { ModalWrapper } from 'components/Modals';
import _ from 'lodash';
import SubmitButton from 'organisms/buttons/SubmitButton';
import Dropdown from 'organisms/Dropdown';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
export default function ActivateLicenseModal({
  organizationList,
  activateModalRef,
  activateLicense,
  is_creating,
  create_successfully
}) {
  const intl = useIntl();
  const organizationOptions = _.map(organizationList, organization => ({
    text: organization.name,
    value: organization._id,
    key: organization._id
  }));
  const [organizationToActivate, setOrganization] = useState(organizationList[0]?._id || '');
  const [loading, setLoading] = useState(false);
  function cancel() {
    setOrganization(organizationList[0]?._id || '');
    setLoading(false);
    activateModalRef.current?.close();
  }
  async function submitForm(evt) {
    evt.preventDefault();
    try {
      setLoading(true);
      await activateLicense(organizationToActivate);
      activateModalRef.current.close();
    } catch (error) {
      console.log(error);
    }
  }
  return <ModalWrapper ref={activateModalRef} size='xs' hasCloseButton={false} title={is_creating ? intl.messages['modal.header.license_activating'] : intl.messages['modal.header.license_activation']}>
			<DialogContent>
				<Box my={0.5}>
					{_.isEmpty(organizationList) ? <Typography variant='body1' style={{
          marginBottom: '8px'
        }}>
							{intl.messages['modals.license.no_organization']}
						</Typography> : <>
							<Typography variant='body1' style={{
            marginBottom: '8px'
          }}>
								{intl.messages['modals.license.header']}
							</Typography>

							<Dropdown variant='outlined' options={organizationOptions} value={organizationToActivate} onChange={({
            target: {
              value
            }
          }) => setOrganization(value)} />
						</>}
				</Box>
			</DialogContent>

			<DialogActions>
				<Button onClick={cancel}>
					<FormattedMessage id='button.cancel' />
				</Button>

				<SubmitButton loading={is_creating} disabled={loading || create_successfully || _.isEmpty(organizationToActivate)} onClick={submitForm}>
					{loading || create_successfully ? <CircularProgress size='20px' /> : <FormattedMessage id='button.confirm' />}
				</SubmitButton>
			</DialogActions>
		</ModalWrapper>;
}
ActivateLicenseModal.propTypes = {
  activateModalRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({
    current: PropTypes.any
  })]),
  organizationList: PropTypes.array,
  activateLicense: PropTypes.func,
  is_creating: PropTypes.bool,
  create_successfully: PropTypes.bool
};